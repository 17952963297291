import React from 'react'
import { Card, CardContent, Grid, Typography, Box } from '@material-ui/core'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function HomePage () {
  const { translation } = useTranslationContext()
  return (
    <Page title={translation['menu.home_page.title']}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Box sx={{ width: '700px', height: '100px' }} justifyContent='center'>
          <Card>
            <CardContent>
              <h1 style={{ textAlign: 'center' }}>{translation['menu.home_page.box1_title']}</h1>
              <Typography style={{ textAlign: 'center', color: '#787878' }}>{translation['menu.home_page.box1_desc']}</Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Box py={20}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <a href='https://agata.io/en/' style={{ textDecoration: 'none' }} target='_blank' rel='noreferrer'>
                <Card style={{ maxWidth: '376px', minHeight: '338px' }}>
                  <CardContent>
                    <Typography style={{ textAlign: 'center' }}>
                      <img
                        src='/algorithm_ag.png'
                        style={{ width: '100%' }}
                        alt='Algorithm AGATA'
                      />
                    </Typography>
                    <h3>{translation['menu.home_page.box2_title']}</h3>
                    <Typography style={{ fontSize: '13px', color: '#787878' }}>{translation['menu.home_page.box2_desc']}</Typography>
                  </CardContent>
                </Card>
              </a>
            </Grid>
            <Grid item xs={12} md={3}>
              <a href='https://blog.agata.io/' style={{ textDecoration: 'none' }} target='_blank' rel='noreferrer'>
                <Card style={{ maxWidth: '376px', minHeight: '338px' }}>
                  <CardContent>
                    <Typography style={{ textAlign: 'center' }}>
                      <img
                        src='/blog_ag.png'
                        style={{ width: '100%' }}
                        alt='Blog AGATA'
                      />
                    </Typography>
                    <h3>{translation['menu.home_page.box3_title']}</h3>
                    <Typography style={{ fontSize: '13px', color: '#787878' }}>{translation['menu.home_page.box3_desc']}</Typography>
                  </CardContent>
                </Card>
              </a>
            </Grid>
            <Grid item xs={12} md={3}>
              <a href='https://help.agata.io/' style={{ textDecoration: 'none' }} target='_blank' rel='noreferrer'>
                <Card style={{ maxWidth: '376px', minHeight: '338px' }}>
                  <CardContent>
                    <Typography style={{ textAlign: 'center' }}>
                      <img
                        src='/help_ag.png'
                        style={{ width: '100%' }}
                        alt='Help AGATA'
                      />
                    </Typography>
                    <h3>{translation['menu.home_page.box4_title']}</h3>
                    <Typography style={{ fontSize: '13px', color: '#787878' }}>{translation['menu.home_page.box4_desc']}</Typography>
                  </CardContent>
                </Card>
              </a>
            </Grid>
            <Grid item xs={12} md={3}>
              <a href='https://www.youtube.com/channel/UCDZjHGjDtXdzuzHBCaYB6UQ' style={{ textDecoration: 'none' }} target='_blank' rel='noreferrer'>
                <Card style={{ maxWidth: '376px', minHeight: '338px' }}>
                  <CardContent>
                    <Typography style={{ textAlign: 'center' }}>
                      <img
                        src='/channel_ag.png'
                        style={{ width: '100%' }}
                        alt='Help AGATA'
                      />
                    </Typography>
                    <h3>{translation['menu.home_page.box5_title']}</h3>
                    <Typography style={{ fontSize: '13px', color: '#787878' }}>{translation['menu.home_page.box5_desc']}</Typography>
                  </CardContent>
                </Card>
              </a>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  )
}
