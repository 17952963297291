import { Grid, Typography } from '@material-ui/core'
import { useTranslationContext } from 'components/TranslationContext'

export function ExpertReviewPage () {
  const { translation } = useTranslationContext()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/*
        <img
          src='/expert-review.png'
          style={{ width: '100%' }}
          alt='expert review preview'
        />
        */}
        <Typography paragraph>
          {translation['pages.InfoPage.content']}
        </Typography>
      </Grid>
    </Grid>
  )
}
