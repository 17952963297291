import { Grid, Typography } from '@material-ui/core'
import { useTranslationContext } from 'components/TranslationContext'

export function ContractProductionPage () {
  const { translation } = useTranslationContext()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/*
        <img
          src='/contract-production-1.jpg'
          style={{ width: '100%' }}
          alt='contract production preview'
        />
        */}
        <Typography paragraph>
          {translation['pages.InfoPage.content']}
        </Typography>
      </Grid>

      {/*
      <Grid item xs={12}>
        <img
          src='/contract-production-2.jpg'
          style={{ width: '100%' }}
          alt='contract production preview'
        />
      </Grid>
      */}
    </Grid>
  )
}
