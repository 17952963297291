import { useEffect, useState } from 'react'
import { Box, Button, Card, FormControl, Grid, IconButton, MenuItem, Select, SelectProps, Typography } from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'
import { getStep1Collections, getStep2Collections, Step2Result, Step1Result, updateSkuTab, updateSurveyTab } from 'api/pricingApi'
import { Table } from 'api/types'
import { useLayoutContext } from 'components/LayoutContext'
import { CustomFormEditable } from 'components/CustomFormEditable'
import { useAuthContext } from 'components/AuthContext'
import { useQueryParams } from 'hooks/queryParams'
import { useLocalStorage } from 'helpers/useLocalStorage'
import { DataTable, Row } from 'components/DataTable'
import { LoadingButton } from 'components/LoadingButton'
import { GradientGraph } from './GradientGraph'
import { DiagGraph } from './DiagGraph'

const storageKey = 'pricing.sku'
const queryParam = 'skuId'

export function PricingPage () {
  const { translation } = useTranslationContext()
  const { startContentLoading, finishContentLoading } = useLayoutContext()
  const [collectionStep1, setCollectionStep1] = useState<Step1Result>()
  const [collectionStep2, setCollectionStep2] = useState<Step2Result>()
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()

  const { queryParams, setQueryParams } = useQueryParams()
  const [storageSkuId, setStorageSkuId] = useLocalStorage(storageKey)
  const [step1Loaded, setStep1Loaded] = useState<boolean>(false)
  const [step2Loaded, setStep2Loaded] = useState<boolean>(false)
  const [isEmptySurveyTab, setIsEmptySurveyTab] = useState<boolean>(true)
  const [isDiagVisible, setIsDiagVisible] = useState<boolean>(false)
  const [skuId, setSkuId] = useState<string>()

  const calcIsEmptySurveyTab = (grid: Table['grid']) => {
    if (grid.rows.length === 0) {
      return true
    }

    if (grid.rows.length > 1) {
      return false
    }

    const row = grid.rows[0]

    for (const column of grid.columns) {
      if (column.field === 'respondentId') {
        continue
      }

      if (row.cells[column.field].value !== '0') {
        return false
      }
    }

    return true
  }

  useEffect(() => {
    if (collectionStep1 !== undefined || step1Loaded) {
      return
    }

    setStep1Loaded(true)

    startContentLoading()

    ;(async () => {
      try {
        const collection = await getStep1Collections()
        const skuRows = collection.skuTab.grid.rows
        const isEmptySkuTab = skuRows.length === 1 && skuRows[0].cells.sku.value === ''

        if (!isEmptySkuTab) {
          const querySkuId = queryParams.get(queryParam)
          const querySkuRow = querySkuId === null ? undefined : skuRows.find(row => `${row.rowId}` === querySkuId)
          const storageSkuRow = storageSkuId === null ? undefined : skuRows.find(row => `${row.rowId}` === storageSkuId)

          const skuRow =
            querySkuRow !== undefined ? querySkuRow
              : storageSkuRow !== undefined ? storageSkuRow
                : skuRows[0]

          setQueryParams({ [queryParam]: `${skuRow.rowId}` })
          setStorageSkuId(`${skuRow.rowId}`)
          setSkuId(`${skuRow.rowId}`)
        }

        setCollectionStep1(collection)
      } catch (error) {
        handleResponseFailure(error as any)
      } finally {
        finishContentLoading()
      }
    })()
  }, [
    startContentLoading,
    finishContentLoading,
    handleResponseFailure,
    setStorageSkuId,
    collectionStep1,
    step1Loaded,
    queryParams,
    setQueryParams,
    storageSkuId
  ])

  useEffect(() => {
    if (collectionStep1 === undefined || skuId === undefined || step2Loaded) {
      return
    }

    const skuRows = collectionStep1.skuTab.grid.rows
    const isEmptySkuTab = skuRows.length === 1 && skuRows[0].cells.sku.value === ''

    if (isEmptySkuTab) {
      return
    }

    setStep2Loaded(true)

    ;(async () => {
      try {
        const collection = await getStep2Collections(skuId)
        setCollectionStep2(collection)
        setIsEmptySurveyTab(calcIsEmptySurveyTab(collection.surveyTab.grid))
      } catch (error) {
        handleResponseFailure(error as any)
      }
    })()
  }, [
    handleResponseFailure,
    collectionStep1,
    step2Loaded,
    skuId
  ])

  if (!collectionStep1) {
    return null
  }

  const skuRows = collectionStep1.skuTab.grid.rows
  const isEmptySkuTab = skuRows.length === 1 && skuRows[0].cells.sku.value === ''

  const handleSubmitSKU = async (names : { names: {id: number, name: string}[] }) => {
    try {
      const grid: Table['grid'] = {
        columns: [],
        rows: names.names.map(row => ({
          cells: {
            sku: {
              type: 'number',
              isEditable: true,
              value: row.name
            }
          },
          rowId: row.id === undefined ? '' : `${row.id}`
        }))
      }

      const result = await updateSkuTab(grid)
      const skuRows = result.grid.rows
      const isEmptySkuTab = skuRows.length === 1 && skuRows[0].cells.sku.value === ''

      if (isEmptySkuTab) {
        setQueryParams({ [queryParam]: undefined })
        setStorageSkuId('')
        setSkuId(undefined)
        setCollectionStep2(undefined)
        setIsEmptySurveyTab(true)
      } else if (!isEmptySkuTab) {
        const foundSkuRow = skuId === undefined ? undefined : skuRows.find(row => `${row.rowId}` === skuId)

        const skuRow =
          foundSkuRow !== undefined ? foundSkuRow
            : skuRows[0]

        setQueryParams({ [queryParam]: `${skuRow.rowId}` })
        setStorageSkuId(`${skuRow.rowId}`)
        setSkuId(`${skuRow.rowId}`)
      }

      setCollectionStep1({ ...collectionStep1, skuTab: result })
      setStep2Loaded(false)
      handleResponseSuccess()
    } catch (err) {
      handleResponseFailure(err as any)
    }
  }

  const handleSkuFilterChange: SelectProps['onChange'] = (e) => {
    const { value } = e.target
    setQueryParams({ [queryParam]: `${value}` })
    setStorageSkuId(`${value}`)
    setSkuId(`${value}`)
    setStep2Loaded(false)
  }

  const handleSurveyTableUpdate = async (grid: Table['grid']) => {
    if (collectionStep2 === undefined) {
      return
    }

    const tab = collectionStep2.surveyTab
    setCollectionStep2({ ...collectionStep2, surveyTab: { ...tab, grid } })
  }

  const addSurveyRow = () => {
    if (collectionStep2 === undefined) {
      return
    }

    const tab = collectionStep2.surveyTab

    const row: Row = {
      cells: {},
      rowId: ''
    }

    for (const column of tab.grid.columns) {
      const value: number = column.field === 'respondentId' ? tab.grid.rows.length + 1 : 0
      const isEditable: boolean = column.field !== 'respondentId'

      row.cells[column.field] = {
        type: 'number',
        value: `${value}`,
        isEditable,
        checked: null
      }
    }

    tab.grid.rows.push(row)

    setCollectionStep2({ ...collectionStep2, surveyTab: tab })
  }

  const deleteSurveyRow = (row: Row, idx: number) => {
    if (collectionStep2 === undefined) {
      return
    }

    const tab = collectionStep2.surveyTab
    tab.grid.rows.splice(idx, 1)
    let respondentId: number = 1

    for (const row of tab.grid.rows) {
      row.cells.respondentId.value = `${respondentId}`
      respondentId++
    }

    setCollectionStep2({ ...collectionStep2, surveyTab: tab })
  }

  const handleSubmitSurvey = async () => {
    if (collectionStep2 === undefined || skuId === undefined) {
      return
    }

    try {
      const tab = collectionStep2.surveyTab
      await updateSurveyTab(skuId, tab.grid)
      setStep2Loaded(false)
      handleResponseSuccess()
    } catch (err) {
      handleResponseFailure(err as any)
    }
  }

  return (
    <Page title={translation['menu.optimal_price']}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" paragraph>
            1. {collectionStep1.skuTab.title}
          </Typography>
          <CustomFormEditable
            header={'' + collectionStep1.skuTab.grid.columns[0].title}
            handleSubmit={handleSubmitSKU}
            items={{
              names: collectionStep1.skuTab.grid.rows.map(row => ({
                id: parseInt(row.rowId),
                name: row.cells.sku.value ?? ''
              }))
            }}
          />
        </Grid>

        { !isEmptySkuTab && <>
          <Grid item xs={12} style={{ marginTop: '2em' }}>
            <Typography variant="h6" paragraph>
              2. {collectionStep2?.surveyTab.title}
            </Typography>

            <Card>
              <Box style={{ margin: '1em 1em 0' }}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    value={skuId}
                    onChange={handleSkuFilterChange}
                    MenuProps={{ MenuListProps: { disablePadding: true } }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>Выберите SKU</MenuItem>
                    {
                      collectionStep1.skuTab.grid.rows.map(row => <MenuItem value={row.rowId}>{row.cells.sku.value}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Box>

              { collectionStep2?.surveyTab && <>
                <DataTable
                  title={translation['pages.PricingPage.price_level']}
                  hint={collectionStep2.surveyTab.description}
                  data={collectionStep2.surveyTab.grid}
                  onDataUpdate={handleSurveyTableUpdate}
                  height='30em'
                  customColumns={[{
                    title: '',
                    render: (row, idx) => <Box textAlign="center">
                      <IconButton
                        size="small"
                        onClick={() => deleteSurveyRow(row, idx)}
                        disabled={false}
                      >
                        <Delete/>
                      </IconButton>
                    </Box>
                  }]}
                />
                <Box textAlign="right" p={2}>
                  <Grid container spacing={1} justify="flex-end">
                    <Grid item>
                      <Button
                        color="primary"
                        startIcon={<Add/>}
                        onClick={() => addSurveyRow()}
                      >
                        {translation['form.add']}
                      </Button>
                    </Grid>

                    <Grid item>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        disableElevation
                        isLoading={false}
                        onClick={() => handleSubmitSurvey()}
                      >
                        {translation['form.save']}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </>}
            </Card>
          </Grid>
        </>}
        { !isEmptySurveyTab && collectionStep2?.crossPoints && <>
          <Grid item xs={12} style={{ marginTop: '2em' }}>
            <Typography variant="h6" paragraph>
              3. {collectionStep2.crossPoints.title}
            </Typography>

            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ padding: '2em 2em 0' }}>
                  <GradientGraph
                    chart={collectionStep2.crossPoints}
                    minPercent={20}
                    maxPercent={97}
                    up={['optimalPricePoint']}
                  />
                </Grid>

                {!isDiagVisible &&
                  <Grid item xs={12} style={{ padding: '2em' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setIsDiagVisible(true)}
                    >
                      {translation['pages.PricingPage.show_chart']}
                    </Button>
                  </Grid>
                }
                {isDiagVisible && <>
                  <Grid item xs={12} style={{ padding: '2em' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setIsDiagVisible(false)}
                    >
                      {translation['pages.PricingPage.hide_chart']}
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <DiagGraph
                      chart={collectionStep2.graphVol}
                      crossPoints={collectionStep2.crossPoints}
                    />
                  </Grid>
                </>}
              </Grid>
            </Card>
          </Grid>
        </>}
      </Grid>
    </Page>
  )
}
