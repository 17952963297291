interface Config extends Intl.NumberFormatOptions {
  locale?: string
}

export function formatNumber (value?: string | number, config?: Config) {
  const {
    maximumFractionDigits = 20,
    locale = 'ru',
    ...options
  } = config ?? {}

  const val = Number(value)

  return (isNaN(val) ? 0 : val).toLocaleString(locale, { maximumFractionDigits, ...options })
}
