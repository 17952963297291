import { Grid, Typography } from '@material-ui/core'
import { Page } from 'components/Page'
import { useTranslationContext } from 'components/TranslationContext'

export function MonitoringPeriodsPage () {
  const { translation } = useTranslationContext()

  return (
    <Page title={translation['menu.units_control_periods']}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/*
          <img
            src='/inspection-frequency.png'
            style={{ width: '100%' }}
            alt='Monitoring periods'
          />
          */}
          <Typography paragraph>
            {translation['pages.InfoPage.content']}
          </Typography>
        </Grid>
      </Grid>
    </Page>
  )
}
